import create from "zustand";

interface BackStore {
  url: string;
  setUrl: (message: string) => void;
}

const useUrlStore = create<BackStore>((set) => ({
    url: "",
    setUrl: (url) => set({ url }),
}));

export default useUrlStore;
